import { useMemberstack } from "../useMemberstack";
import { filterNullableValues } from "../../utils";

export type PurchasePlansWithCheckoutParams = {
  priceId: string;
  cancelUrl?: string;
  successUrl?: string;
  autoRedirect?: boolean;
};

export const useCheckout = () => {
  const memberstack = useMemberstack();

  const checkout = async (params: PurchasePlansWithCheckoutParams) =>
    await memberstack.purchasePlansWithCheckout(
      //@ts-ignore
      filterNullableValues(
        {
          priceId: params.priceId,
          cancelUrl: params.cancelUrl,
          successUrl: params.successUrl,
          autoRedirect: params.autoRedirect,
        },
        { includeFalseVals: true }
      )
    );

  return checkout;
};
