import React from "react";
import { useMemberstack } from "./useMemberstack";

export const useMember = () => {
  const memberstack = useMemberstack();
  const [member, setMember] = React.useState<any>(null);
  const [memberJSON, setMemberJSON] = React.useState<any>(null);

  async function getMember() {
    try {
      const { data: currentMember } = await memberstack.getCurrentMember();
      currentMember ? setMember(currentMember) : setMember(null);
      const { data: jsonData } = await memberstack.getMemberJSON();
      jsonData ? setMemberJSON(jsonData?.json) : setMemberJSON(null);
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getMember();
  }, []);

  return {
    member,
    memberJSON,
    updateEmail: async (email: string) => await memberstack.updateMemberAuth({ email }),
    updatePassword: async (oldPassword: string, newPassword: string) =>
      await memberstack.updateMemberAuth({ oldPassword, newPassword }),
    updateCustomFields: async (customFields: any) =>
      await memberstack.updateMember({ customFields }),
    updateMemberJSON: async (json: object) => await memberstack.updateMemberJSON({ json }),
  };
};
