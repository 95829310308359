import { GetCurrentMemberPayload } from "@memberstack/dom/lib/types";
import React from "react";
import {
  useMemberstackModal,
  SignupModalProps,
  LoginModalProps,
  ProfileModalProps,
  ForgotModalProps,
  ResetModalProps,
} from "../hooks/useMemberstackModal";
import { DistributiveOmit } from "../types";

export enum ModalType {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  PROFILE = "PROFILE",
}

type AllModalProps =
  | LoginModalProps
  | SignupModalProps
  | ProfileModalProps
  | ForgotModalProps
  | ResetModalProps;

interface Callbacks {
  onSuccess?: ({
    data,
    type,
  }: {
    data: GetCurrentMemberPayload["data"];
    type: ModalType | "CLOSED";
  }) => void;
  onError?: (error: any) => void;
}

// add Callbacks to AllModalProps
type AllModalPropsWithCallbacks = AllModalProps & Callbacks;

type ModalPropsWithoutType = DistributiveOmit<AllModalPropsWithCallbacks, "type">;

type CreateFactoryModal = (type: ModalType) => (props: ModalPropsWithoutType) => JSX.Element;

const modalFactory: CreateFactoryModal = (type) => {
  return function Modal(props?: ModalPropsWithoutType) {
    const { openModal, hideModal } = useMemberstackModal();
    const onSuccess = props?.onSuccess;
    const onError = props?.onError;

    const handleModal = async () => {
      try {
        const { data } = await openModal({ ...props, type });
        onSuccess?.({ data, type });
        hideModal();
      } catch (error) {
        onError?.(error);
        hideModal();
      }
    };

    React.useEffect(() => {
      handleModal();
    }, []);

    return null;
  };
};

export const SignInModal = modalFactory(ModalType.LOGIN);
export const SignUpModal = modalFactory(ModalType.SIGNUP);
export const ForgotPasswordModal = modalFactory(ModalType.FORGOT_PASSWORD);
export const ResetPasswordModal = modalFactory(ModalType.RESET_PASSWORD);
export const ProfileModal = modalFactory(ModalType.PROFILE);
