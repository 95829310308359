import React from "react";
import msDom from "@memberstack/dom";

declare global {
  interface Window {
    __MSDOM: any;
  }
}

type Config = Parameters<typeof msDom["init"]>;

interface MemberstackProviderProps {
  config: Config[0];
  children: JSX.Element | JSX.Element[];
}

const MemberstackContext = React.createContext<{
  memberstack: null | ReturnType<typeof msDom["init"]>;
}>({ memberstack: null });

export const useMemberstackContext = () => {
  return React.useContext(MemberstackContext);
};

export const MemberstackProvider = (props: MemberstackProviderProps) => {
  const memberstack = msDom.init(props.config);

  React.useEffect(() => {
    const loader = setInterval(() => {
      if (!window.__MSDOM?.load && !document.getElementById("_ms-iframe")) {
        window.__MSDOM?.load({ publicKey: (props.config as { publicKey: string }).publicKey });
      } else {
        clearInterval(loader);
      }
    }, 200);

    return () => clearInterval(loader);
  }, []);

  return (
    <MemberstackContext.Provider value={{ memberstack }}>
      {props.children}
    </MemberstackContext.Provider>
  );
};
