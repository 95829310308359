import React, { FC } from "react";
import { useMemberstack } from "../hooks/useMemberstack";

export interface MemberstackProtectedProps {
  allow?: {
    permissions?: string[];
    plans?: string[];
  };
  onUnauthorized?: JSX.Element | (() => void);
  children: JSX.Element;
  fallback?: JSX.Element;
}

enum State {
  LOADING = "LOADING",
  UNAUTHORIZED = "UNAUTHORIZED",
  AUTHORIZED = "AUTHORIZED",
}

export const MemberstackProtected: FC<MemberstackProtectedProps> = (props) => {
  const memberstack = useMemberstack();
  const [state, setState] = React.useState(State.LOADING);

  if (!memberstack) {
    throw new Error("<MemberstackProtected> can only be used within <MemberstackProvider>");
  }

  if (
    props.onUnauthorized &&
    typeof props.onUnauthorized !== "function" &&
    !React.isValidElement(props.onUnauthorized)
  ) {
    throw new Error("onUnauthorized must be a function or a valid React element");
  }

  function getMemberAuthorization(member) {
    if (!member) {
      setState(State.UNAUTHORIZED);
      return;
    }

    const { permissions, plans } = props.allow || {};

    if (permissions?.length) {
      if (!permissions.some((per) => (member?.permissions as string[])?.includes(per))) {
        setState(State.UNAUTHORIZED);
        return;
      }
    }

    if (plans?.length) {
      if (!plans.some((planId) => member?.planConnections?.find((con) => con.planId === planId))) {
        setState(State.UNAUTHORIZED);
        return;
      }
    }

    setState(member ? State.AUTHORIZED : State.UNAUTHORIZED);
  }

  async function getMember() {
    const { data: member } = await memberstack.getCurrentMember();
    getMemberAuthorization(member);
  }

  React.useEffect(() => {
    let isMounted = true;
    let listener: any;

    if (isMounted) {
      listener = memberstack?.onAuthChange(getMemberAuthorization);
      getMember();
    }

    return () => {
      isMounted = false;
      listener.unsubscribe();
    };
  }, []);

  switch (state) {
    case State.LOADING:
      return props.fallback || null;

    case State.AUTHORIZED:
      return props.children;

    case State.UNAUTHORIZED:
      if (React.isValidElement(props.onUnauthorized)) {
        return props.onUnauthorized as JSX.Element;
      } else if (typeof props.onUnauthorized === "function") {
        props.onUnauthorized();
      }

      return null;
    default:
      return null;
  }
};
