import { useMemberstack } from "../useMemberstack";
import { filterNullableValues } from "../../utils";

export type LaunchStripeCustomerPortalParams = {
  priceIds: string[];
  returnUrl?: string;
  autoRedirect?: boolean;
};

export const useCustomerPortal = (params: LaunchStripeCustomerPortalParams) => {
  const memberstack = useMemberstack();

  const launchPortal = async () =>
    await memberstack.launchStripeCustomerPortal(
      //@ts-ignore
      filterNullableValues(
        {
          priceIds: params.priceIds,
          returnUrl: params.returnUrl,
          autoRedirect: params.autoRedirect,
        },
        { includeFalseVals: true }
      )
    );

  return launchPortal;
};
