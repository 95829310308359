import React from "react";
import { useMemberstack } from "./useMemberstack";

export enum State {
  LOADING = "LOADING",
  UNAUTHORIZED = "UNAUTHORIZED",
  AUTHORIZED = "AUTHORIZED",
}

export const useAuth = () => {
  const memberstack = useMemberstack();
  const [auth, setAuth] = React.useState<any>(null);

  function getMemberAuthorization(currentMember: any) {
    currentMember
      ? setAuth({
          userId: currentMember.id,
          status: State.AUTHORIZED,
        })
      : setAuth({
          userId: null,
          status: State.UNAUTHORIZED,
        });
    return;
  }

  async function getMember() {
    const { data: currentMember } = await memberstack.getCurrentMember();
    getMemberAuthorization(currentMember);
  }

  React.useEffect(() => {
    let isMounted = true;
    let listener;

    if (isMounted) {
      listener = memberstack?.onAuthChange(getMemberAuthorization);
      getMember();
    }

    return () => {
      isMounted = false;
      listener?.unsubscribe();
    };
  }, []);

  return {
    ...auth,
    isLoggedIn: auth?.status === State.AUTHORIZED,
    getToken: () => memberstack.getMemberCookie(),
    signOut: async () => await memberstack.logout(),
  };
};
