import React from "react";
import { useAuth, State } from "../../hooks/useAuth";

interface AuthLogicProps {
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element | JSX.Element[];
}

const logicWrapper = (fn: (x: any) => any) => {
  return function LogicWrapper(props: AuthLogicProps) {
    const auth = useAuth();
    const condition = React.useMemo(() => fn(auth), [auth]);
    if (auth.status === State.LOADING) {
      return props.fallback || null;
    }
    return condition ? props.children : null;
  };
};

export const LoggedIn = logicWrapper((auth) => auth.isLoggedIn);
export const LoggedOut = logicWrapper((auth) => !auth.isLoggedIn);
