import React from "react";
import { useMemberstack } from "../useMemberstack";
import { filterNullableValues } from "../../utils";

export interface ForgotModalProps {
  type: "FORGOT_PASSWORD";
}

export interface ResetModalProps {
  type: "RESET_PASSWORD";
}

export interface LoginModalProps {
  type: "LOGIN";
}

export interface SignupModalProps {
  type: "SIGNUP";
  planId?: string[];
}

export interface ProfileModalProps {
  type: "PROFILE";
}

export const useMemberstackModal = () => {
  const memberstack = useMemberstack();

  const config = React.useRef(null);
  const [show, setShow] = React.useState(false);

  function openModal(
    openModalProps?:
      | ForgotModalProps
      | ResetModalProps
      | LoginModalProps
      | SignupModalProps
      | ProfileModalProps
  ): Promise<any> {
    config.current = filterNullableValues({
      ...config.current,
      type: openModalProps.type,
      planId: (openModalProps as SignupModalProps).planId,
    });

    setShow(true);
    // @ts-ignore
    //if (!loaded || visible) return;

    return memberstack.openModal(
      config.current.type,
      filterNullableValues({
        planId: config.current.planId,
      })
    );
  }

  function hideModal() {
    setShow(false);
    return memberstack.hideModal();
  }

  return {
    openModal,
    hideModal,
  };
};
