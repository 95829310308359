import { useMemberstackContext } from "../context/MemberstackProvider";

export const useMemberstack = () => {
  const { memberstack } = useMemberstackContext();

  if (!memberstack) {
    throw new Error("useMemberstack can only be used within <MemberstackProvider>");
  }

  return memberstack;
};
