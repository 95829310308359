// if includeFalseVals is true, then the returned array will
// include false values and zero values || exclude undefined and null values
// otherwise, it will only return truthy values.

interface IFilterNullableValues {
  includeFalseVals?: boolean;
}

export const filterNullableValues = (
  obj: any,
  { includeFalseVals = false }: IFilterNullableValues = {}
) =>
  Object.entries(obj)
    .filter(([, y]) => (includeFalseVals ? y !== null && y !== undefined : y))
    .reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});
